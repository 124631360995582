import React, { FC } from 'react';
import Slider from 'react-slick';

import Button from 'gatsby-theme-husky/src/common/Button';
import Icon from 'gatsby-theme-husky/src/common/IconCustom';
import { DefaultCarouselSettings } from 'gatsby-theme-husky/src/components/WithCarousel/constants';

import {
  IWithCarouselProps,
  ICarouselControlProps,
} from 'gatsby-theme-husky/src/components/WithCarousel/model';

import 'gatsby-theme-husky/src/components/PDCarousel/PDCarousel.scss';
import 'gatsby-theme-husky/src/styles/common/slick-styles.scss';

import './WithCarouselOverride.scss';

const CarouselControl = ({
  className,
  classes,
  onClick,
  ariaLabel,
  iconName,
}: ICarouselControlProps) => (
  <Button
    classes={classes}
    ariaLabel={ariaLabel}
    clickHandler={onClick}
    variant="secondary"
    disabled={className?.includes('disabled')}
  >
    <Icon icon={iconName} colorCount={2} />
  </Button>
);

const WithCarousel: FC<IWithCarouselProps> = ({
  slides,
  settings = {},
  slideComponent: SlideComponent,
  ariaNext,
  ariaPrev,
}) => {
  if (slides && slides.length > 1) {
    const defaultSettings = {
      ...DefaultCarouselSettings,
      prevArrow: (
        <CarouselControl ariaLabel={ariaPrev} iconName="chevron-left-colored" classes="prev-btn" />
      ),
      nextArrow: (
        <CarouselControl ariaLabel={ariaNext} iconName="chevron-right-colored" classes="next-btn" />
      ),
      customPaging: () => <button type="button" aria-label={ariaNext} className="dot-btn" />,
    };

    return (
      <div className="carousel-wrapper">
        <Slider {...{ ...defaultSettings, ...settings }}>
          {slides.map((item, idx) => (
            <SlideComponent {...item} key={idx} isLazyLoading={!!idx} />
          ))}
        </Slider>
      </div>
    );
  }

  return <SlideComponent {...slides?.[0]} />;
};

export default WithCarousel;
